// ** Redux Imports
import { combineReducers } from 'redux'

// ** Reducers Imports
import auth from './auth'
import navbar from './navbar'
import layout from './layout'
import users from '@src/views/users/store/reducer'
import customers from '@src/views/customers/store/reducer'
import orders from '@src/views/orders/store/reducer'
import delivery from '@src/views/delivery/store/reducer'
import templates from '@src/views/templates/store/reducer'

const rootReducer = combineReducers({
  auth,
  navbar,
  layout,
  users,
  customers,
  orders,
  delivery,
  templates
})

export default rootReducer
