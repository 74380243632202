// ** Initial State
const initialState = {
  data: [],
  total: 1,
  params: {},
  selectedOrder: null,
  showModalGeneateMealPlan: false,
  webOrderDetailId: null,
  mealPlan: null,
  showMealPlanDetailPage: false,
  shippingAddressId: null, 
  modalShippingAddresses: [],
  modifierOptions: [],
  comboItems: [],
  userInfo: null,
  userTDEEInfo: {
    tdee: null,
    bmi: null,
    category: null,
    bmr: null
  }
}

const orders = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_DATA':
      return {
        ...state,
        data: action.data,
        total: action.totalPages,
        params: action.params
      }
    case 'GET_ORDER':
      return { ...state, selectedOrder: action.selectedOrder }
    case 'UPDATE_ORDER':
      return { ...state }
    case 'SHOW_MODAL_GENERATE_MEAL_PLAN':      
      return {
        ...state, 
        showModalGeneateMealPlan: true, 
        webOrderDetailId : action.web_order_detail_id,
        userInfo: action.userInfo
      }
    case 'CLOSE_MODAL_GENERATE_MEAL_PLAN':    
      return {...state, showModalGeneateMealPlan: false, webOrderDetailId : null, mealPlan: null}
    case 'UPDATE_MEAL_PLAN':
      return {...state, mealPlan: action.meals}
    case 'SHOW_MEAL_PLAN_PAGE':
      return {...state, showMealPlanDetailPage: true, showModalGeneateMealPlan: false}
    case 'HIDE_MEAL_PLAN_PAGE':
      return {...state, showMealPlanDetailPage: false, showModalGeneateMealPlan: false}
    case 'UPDATE_SHIPPING_ADDRESSES':
      return {...state, modalShippingAddresses: action.shipping_addresses}
    case 'SET_SHIPPING_ADDRESSES_ID':
      return {...state, shippingAddressId: action.shipping_address_id}
    case 'GET_MODIFIERS':
      return {...state, modifierOptions: action.modifiers}
    case 'CANCEL_MEAL_PLAN':
      return {...state, showMealPlanDetailPage: false, showModalGeneateMealPlan: false}
    case 'GET_ITEMS':
      return { ...state, comboItems: action.comboItems }
    case 'UPDATE_TDEE':
      return { ...state, userTDEEInfo: action.userTDEEInfo }
    default:
      return { ...state }
  }
}
export default orders
