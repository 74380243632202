// ** Initial State
const initialState = {
  data: [],
  total: 1,
  from: 1,
  params: {}
}

const templates = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_DATA':
      return {
        ...state,
        data: action.data,
        total: action.totalPages,
        from: action.from,
        params: action.params
      }
    case 'GENERATE_TEMPLATE':
      return { ...state }
    default:
      return { ...state }
  }
}
export default templates
