// ** Initial State
const initialState = {
  selectedDelivery: null,
  comboAddress: [],
  comboRiders: [],
  comboItems: []
}

const delivery = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_DELIVERY':
      return { ...state, selectedDelivery: action.selectedDelivery }
    case 'GET_ADDRESS':
      return { ...state, comboAddress: action.comboAddress }
    case 'GET_RIDERS':
      return { ...state, comboRiders: action.comboRiders }
    case 'GET_ITEMS':
      return { ...state, comboItems: action.comboItems }
    case 'ADD_DELIVERY':
      return { ...state }
    case 'UPDATE_DELIVERY':
      return { ...state }
    case 'DELETE_DELIVERY':
      return { ...state }
    default:
      return { ...state }
  }
}
export default delivery
